












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsCmsList extends Vue {
  @Prop({ default: '' }) private catKey!: string; // key值
  private Page: number = 1;
  private PageSize: number | null = 0;
  contents: object[] = [];

  swiperOptionNews: object = {
    slidesPerView: 3,
    spaceBetween: 60,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiperNews-button-next',
      prevEl: '.swiperNews-button-prev'
    },
    // autoHeight: true, // 高度随内容变化
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true // 修改swiper的父元素时，自动初始化swiper
  };

  // 获取关于cms内容列表
  getContents () {
    if (this.isMobile) {
      this.PageSize = 3;
    } else {
      this.PageSize = null;
    }
    this.$Api.cms.getContentsByCatKeyEx({ Key: this.catKey, Page: this.Page, PageSize: this.PageSize, IsMobile: this.isMobile }).then((result) => {
      this.contents = result.Data;
      console.log(result, 'getContentsByCatKeyExNEWS');
    });
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.getContents();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getContents();
  }
}
